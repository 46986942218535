<template>
  <div class="scroll-page">
    <ModuleBanner :list="bannerList" v-if="bannerList.length > 0"/>
    <ModuleGrid :list="gridList"/>
    <!-- <ModuleQuickNav :list="quickNav" v-if="quickNav.length > 0"/> -->
    <ModuleService :list="serviceList"/>
    <ModuleContact/>
  </div>
</template>

<script>
  import { Toast } from 'vant';
  import { getHomeInfo } from '@/api/common';

  export default {
    components: {
      ModuleBanner: () => import('./module/Banner'),
      ModuleGrid: () => import('./module/Grid'),
      // 取消H5端自助查询
      // ModuleQuickNav: () => import('./module/QuickNav'),
      ModuleService: () => import('./module/Service'),
      ModuleContact: () => import('./module/Contact')
    },
    data() {
      return {
        bannerList: [],
        gridList: [],
        serviceList: []
        // quickNav: []
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getHomeInfo().then(res => {
          this.bannerList = res.data.banner_list
          this.gridList = res.data.module_list
          this.serviceList = res.data.service_list
          // this.quickNav = res.data.search_list
          toast.clear()
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>